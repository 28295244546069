import { Link, useNavigate, useNavigation } from "react-router-dom";
import "../App.scss";
import AppLogo from "../assets/closette-logo.png";
import ScrollToTop from "../hooks/useScrollToTop";

export const Navbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <ScrollToTop />
      <nav className="app__navbar">
        <img
          src={AppLogo}
          className="app__navbar__circle"
          onClick={() => navigate("/")}
        />
      </nav>
    </>
  );
};
