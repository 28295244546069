import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import PrivacyPolicyPage from "./pages/PrivacyPollicy";
import TermsAndConditions from "./pages/TermsAndCondition";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms",
    element: <TermsAndConditions />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
