import "../App.scss";
import { useState } from "react";

import HeroImage from "../assets/hero_phone_group.png";
import AppDownloadImage from "../assets/app_download_img.png";

import PlayStoreIcon from "../assets/download_app.png";
import AppStoreIcon from "../assets/download_play.png";

import BenifitHeroImg from "../assets/benefits_img.png";
import BenifitIcon1 from "../assets/benefit1.png";
import BenifitIcon2 from "../assets/benefit2.png";
import BenifitIcon3 from "../assets/benefit3.png";

import featureCardImg1 from "../assets/feature-card1.png";
import featureCardImg2 from "../assets/feature-card2.png";
import featureCardImg3 from "../assets/feature-card3.png";

import LeftIcon from "../assets/arrow-left.png";
import RIghtIcon from "../assets/arrow-right.png";

import FAQS from "../faqs";
import TESTIMONIALS from "../testimonial";

import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

function Home() {
  const [currentItem, setCurrentItem] = useState(0);

  const showMore = () => {
    if (currentItem < TESTIMONIALS.length - 1) {
      let count = currentItem + 1;
      setCurrentItem(count);
    }
  };
  const showLess = () => {
    if (currentItem >= 1) {
      let count = currentItem - 1;
      setCurrentItem(count);
    }
  };

  return (
    <>
      {/* navbar */}

      <div className="app">
        <div className="container">
          {/* navigation */}
          <Navbar />
        </div>
        {/* hero section */}
        <main>
          <section className="app__hero">
            <div className="container text-center">
              <div className="hero-header">
                <h1>
                  <span className="highlight">Closette</span> AI Powered
                </h1>
                <h1>Wardrobe assistant</h1>
              </div>

              <p className="w-50 text-center mx-auto my-4">
                Closette is an AI powered assistant effectively utilize and
                manage your clothing collection.
              </p>

              <div className="app__action d-flex justify-content-center my-5 py-3">
                <div className="app__download__actions">
                  <img className="img" src={AppStoreIcon} />
                  <img className="img" src={PlayStoreIcon} />
                </div>
              </div>

              <div className="app__hero__image">
                <img src={HeroImage} />
              </div>
            </div>
          </section>
        </main>

        <section className="app__about app__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="headline d-flex align-items-center">
                  <span className="headline__line light"></span>
                  <span className="headline__text text-white">About Us</span>
                </div>
                <h1 className="mt-3 mb-5 fw-bold display-4 text-white">
                  What is a Closette?
                </h1>

                <p className="w-75 text-white">
                  CLOSETTE provides personalized outfit recommendations by
                  analyzing your closet items, occasion, event, and mood. At
                  Team Closet, we are dedicated to revolutionizing the fashion
                  industry through the use of data and cutting-edge
                  technologies. Our mission is to address the common challenges
                  and frustrations we face in our daily fashion lives by
                  introducing "smart fashion" through AI-powered digital closets
                  and data-driven solutions. With Closet, you can bid farewell
                  to the struggles of deciding what to purchase, the stress of
                  last-minute outfit choices, and the frustration of cluttered
                  wardrobes. Embark on your smart fashion journey with Closet
                  today.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="app__features app__section">
            <div className="container">
              <div className="app__features__header">
                <div className="headline d-flex align-items-center">
                  <span className="headline__line"></span>
                  <span className="headline__text">Closette Top Features</span>
                </div>
              </div>
              <h1 className="mb-5 fw-bold text-center">
                Closette Top Features
              </h1>
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <div className="card feature__card border-0">
                    <img
                      src={featureCardImg1}
                      className="card-img-top "
                      alt=""
                    />
                    <div className="card-body">
                      <h2 class="card-title fw-bold my-4">
                        Create your digital closet with your personal style
                      </h2>
                      <p className="card-text text-muted">
                        Capture, select, or import items to your closet using
                        your phone's camera, gallery, or Google images. The
                        more, the merrier!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card feature__card border-0">
                    <img
                      src={featureCardImg2}
                      className="card-img-top "
                      alt=""
                    />
                    <div className="card-body">
                      <h2 class="card-title fw-bold my-4">
                        Closette creates outfit for you using what you own
                      </h2>
                      <p className="card-text text-muted">
                        Closette gives you suggestions based on-what-to-wear
                        based on your closet items, occasion or event, and mood.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card feature__card border-0">
                    <img
                      src={featureCardImg3}
                      className="card-img-top "
                      alt=""
                    />
                    <div className="card-body">
                      <h2 class="card-title fw-bold my-4">
                        Discover new items for your closet!
                      </h2>
                      <p className="card-text text-muted">
                        Closette gives you suggestions on items to buy that fit
                        the clothes you already have. from your digital wadrobe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="app__benefits">
            <div className="container">
              <div className="row gx-5">
                <div className="col-lg-6 col-sm-12">
                  <Heading title={"Our Benefit"} />
                  <h1 className="mb-5">
                    Explore your <br /> Personal Style
                  </h1>
                  <img className="img-fluid" src={BenifitHeroImg} alt="" />
                </div>

                <div className="col-sm-12 col-lg-6">
                  <div className="row g-5">
                    <div className="col-lg-6 col-sm-12">
                      <div className="benefit__card">
                        <img className="img-fluid" src={BenifitIcon1} alt="" />
                        <h5 className="my-3">Make Outfits</h5>
                        <p>
                          Make your own outfit ideas and find your personal
                          style by our AI recommendation
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="benefit__card">
                        <img className="img-fluid" src={BenifitIcon2} alt="" />
                        <h5 className="my-3">Share</h5>
                        <p>
                          Share outfit ideas and get inspired from millions of
                          ideas from fashionistas all over the world.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="benefit__card">
                        <img className="img-fluid" src={BenifitIcon3} alt="" />
                        <h5 className="my-3">Plan & Log outfits</h5>
                        <p>
                          Planning and logging your daily outfits will help you
                          find your favorite clothes and styles.
                        </p>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-primary benefit__action__btn">
                    learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="app__section">
          <div className="container">
            <div className="app__testionial">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="headline d-flex align-items-center">
                    <span className="headline__line"></span>
                    <span className="headline__text">
                      Closette Top Features
                    </span>
                  </div>
                  <h1 className="mb-5 fw-bold">What our users says</h1>
                </div>
                <div className="counters d-flex">
                  <div
                    style={{ opacity: currentItem === 0 ? 0.2 : 1 }}
                    onClick={() => showLess()}
                  >
                    <img src={LeftIcon} />
                  </div>
                  <div
                    style={{
                      opacity: currentItem >= TESTIMONIALS.length - 1 ? 0.2 : 1,
                    }}
                    onClick={() => showMore()}
                  >
                    <img src={RIghtIcon} />
                  </div>
                </div>
              </div>

              <div className="row my-5">
                {TESTIMONIALS[currentItem] && (
                  <div className="col-ms-12 col-lg-6">
                    <div class="card mb-3 app__testionial__card">
                      <div class="row g-0">
                        <div class="col-md-4">
                          <div className="d-flex h-100">
                            <img
                              src={TESTIMONIALS[currentItem].img}
                              class="img-fluid testimonial-img"
                            />
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="card-body">
                            <h5 class="card-title fw-bold">
                              {TESTIMONIALS[currentItem].title}
                            </h5>
                            <h6>{TESTIMONIALS[currentItem].subTitle}</h6>
                            <p class="card-text mt-4">
                              {TESTIMONIALS[currentItem].desc}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {TESTIMONIALS[currentItem + 1] && (
                  <div className="col-ms-12 col-lg-6">
                    <div class="card mb-3 app__testionial__card">
                      <div class="row g-0">
                        <div class="col-md-4">
                          <div className="d-flex h-100">
                            <img
                              src={TESTIMONIALS[currentItem + 1].img}
                              class="img-fluid testimonial-img"
                            />
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="card-body">
                            <h5 class="card-title fw-bold">
                              {TESTIMONIALS[currentItem + 1].title}
                            </h5>
                            <h6>{TESTIMONIALS[currentItem + 1].subTitle}</h6>
                            <p class="card-text mt-4">
                              {TESTIMONIALS[currentItem + 1].desc}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="app__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <Heading title={"FAQ"} />
                <h1 className="mb-5 fw-bold">Frequently asked questions</h1>

                <div className="faqs">
                  {FAQS.map((item, index) => {
                    return (
                      <>
                        <details key={index} open={index === 0}>
                          <summary>
                            <span>{item.q}</span>

                            <span className="opener closed">+</span>
                            <span className="opener opened">-</span>
                          </summary>
                          <p className="pt-2">{item.a}</p>
                        </details>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="app__download">
              <h1>Download the Closette App</h1>
              <p>
                The Closet app is available on Google Play and the App Store.
                Want to manage your wardrobe digitally anywhere and anytime?
                Let's download the application now.
              </p>
              <div className="app__download__actions">
                <img className="img" src={AppStoreIcon} />
                <img className="img" src={PlayStoreIcon} />
              </div>
              <img
                className="app__download__img img-fluid"
                src={AppDownloadImage}
              />
            </div>
          </div>
        </section>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

const Heading = ({ title }) => {
  return (
    <>
      <div className="headline d-flex align-items-center">
        <span className="headline__line"></span>
        <span className="headline__text">{title}</span>
      </div>
    </>
  );
};

export default Home;
