import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
const PrivacyPollicy = () => {
  return (
    <>
      <Navbar />

      {/* privacy policy  */}
      <h6 className="text-center my-5 fw-bold pt-5">Privacy Policy</h6>
      <div className="row w-100">
        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2 p-3">
          <p className="mb-5">
            This Policy (the "Policy") explains the kind of information to be
            collected in the course of using the application service, closette
            (and its web site). The Policy also explains the way of their
            treatment. The company regards personal information of the users as
            important. The company is obliged to inform them of 1) the purpose
            of collecting the information, 2) method of company's usage of the
            personal information, and 3) the measures taken by the company for
            protection of those personal information. This Policy will be
            effective on the 1st day of December, 2020 and, in case of
            modification thereof, the company will make public notice through
            posting it on the bulletin board of the service (or individual
            notice through sending e-mails).{" "}
          </p>

          <h5>1. Information to be collected and method of collection</h5>

          <h6>(1) Personal information items to be collected</h6>
          <p>
            Personal information items to be collected by the Company are as
            follows:
          </p>

          <ul>
            <li>Information provided by the users</li>
            <ul>
              <li>
                Internet membership service: name, birthdate, email address
                (ID), password, phone number, and gender.
              </li>
              <li>
                Online payment service: name, address, phone number, email
                address, payment information including account number and card
                number.
              </li>
              <li>
                Images uploaded by users and related information that users
                provide.
              </li>
            </ul>
            <li>Information collected while the users use services</li>
            <ul>
              <li>
                Equipment information: Equipment identifier, hardware and
                software version, and use information of website or application.
              </li>
              <li>
                Log information: IP address, log data, use time, internet
                protocol address, cookie and web beacon.
              </li>
            </ul>
          </ul>

          <h6>(2) Method of collection</h6>
          <p>
            The Company collects the information of users in the following ways:
          </p>
          <ul>
            <li>
              Application, webpage, written form, fax, telephone calling,
              e-mailing.
            </li>
            <li>Provided by partner companies.</li>
          </ul>

          <h5>2. Use of collected information</h5>
          <p>
            The Company uses the collected information of users for the
            following purposes:
          </p>
          <ul>
            <li>Member management and identification.</li>
            <li>
              To detect and deter unauthorized or fraudulent use of or abuse of
              the service.
            </li>
            <li>
              Performance of contract, service fee payment and service fee
              settlement regarding provision of services demanded by the users.
            </li>
            <li>
              Improvement of existing services and development of new services.
            </li>
            <li>
              Making notice of function of company sites or applications or
              matters on policy change.
            </li>
            <li>
              To help you connect with other users you already know and to allow
              other users to connect with you (only with your permission).
            </li>
            <li>
              To make statistics on member’s service usage, to provide services
              and place advertisements based on statistical characteristics.
            </li>
            <li>
              To provide information on promotional events as well as the
              opportunity to participate.
            </li>
            <li>To comply with applicable laws or legal obligations.</li>
            <li>
              Use of information with prior consent of the users (for example,
              utilization of marketing advertisement).
            </li>
          </ul>

          <p>
            If the Company desires to use the information other than those
            expressly stated in this Policy, the Company will obtain consent
            from the users.
          </p>

          <h5>3. Disclosure of collected information</h5>
          <p>
            Except for the following cases, the Company will not disclose
            personal information with a 3rd party:
          </p>
          <ul>
            <li>
              When the Company discloses the information with its affiliates,
              partners, and service providers.
            </li>
            <ul>
              <li>
                When the Company's affiliates, partners, and service providers
                carry out services such as bill payment, execution of orders,
                products delivery and dispute resolution (including disputes on
                payment and delivery) for and on behalf of the Company.
              </li>
            </ul>
            <li>When the users consent to disclose in advance.</li>
            <ul>
              <li>
                When the user selects to allow his or her personal information
                to be shared with the sites or platform of other companies such
                as social networking sites.
              </li>
              <li>
                Other cases where the user gives prior consent for sharing his
                or her personal information.
              </li>
            </ul>
            <li>When disclosure is required by the laws.</li>
            <ul>
              <li>If required to be disclosed by the laws and regulations.</li>
              <li>
                If required to be disclosed by the investigative agencies for
                detecting crimes in accordance with the procedure and method as
                prescribed in the laws and regulations.
              </li>
            </ul>
          </ul>

          <h5>4. Cookies, Beacons, and Similar Technologies</h5>
          <p>
            The Company may collect collective and impersonal information
            through 'cookies' or 'web beacons'.
          </p>

          <p>
            Cookies are very small text files to be sent to the browser of the
            users by the server used for the operation of the websites of the
            Company and will be stored in hard-disks of the users' computer.
          </p>

          <p>
            Web beacon is a small quantity of code which exists on the websites
            and e-mails. By using web beacons, we may know whether a user has
            interacted with certain web pages or the contents of email.
          </p>

          <p>
            Information collected via cookies and web beacons are used for
            evaluating, improving services and setting-up users' experiences so
            that the company can improve service quality to the users.
          </p>

          <p>
            The items of cookies to be collected by the Company and the purpose
            of such collection are as follows:
          </p>

          <ul>
            <li>
              <strong>Strictly necessary cookies</strong>
            </li>
            <p>
              This cookie is a kind of indispensable cookie for the users to use
              the functions of the services of the Company. Unless the users
              allow this cookie, the services such as shopping cart or
              electronic bill payment cannot be provided. This cookie does not
              collect any information which may be used for marketing or
              memorizing the pages visited by the users.
            </p>
            <ul>
              <li>Examples of necessary cookies:</li>
              <ul>
                <li>
                  Memorize the information entered in an order form while
                  searching other pages during web browser session.
                </li>
                <li>
                  For the page of products and check-out, memorize ordered
                  services.
                </li>
              </ul>
            </ul>
            <li>
              <strong>Performance cookies</strong>
            </li>
            <p>
              These cookies collect information on how the users use the
              services of the Company such as the information of the pages which
              are visited by the users most. This data helps the Company to
              optimize its services so that the users can use the services more
              comfortably. This cookie does not collect any information about
              the users. Any and all information collected by this cookie will
              be processed collectively, and anonymity will be guaranteed.
            </p>
            <ul>
              <li>Examples of performance cookies:</li>
              <ul>
                <li>
                  Web analysis: provide statistical data on the ways of using
                  the services.
                </li>
                <li>
                  Advertisement response fee: check the effect of advertisement
                  of the Company.
                </li>
              </ul>
            </ul>
            <li>
              <strong>Functionality cookies</strong>
            </li>
            <p>
              These cookies are used for memorizing the setups so that the
              Company provides services and improves the visit of users. Any
              information collected by this cookie does not identify the users
              individually.
            </p>
            <ul>
              <li>Examples of functionality cookies:</li>
              <ul>
                <li>
                  Memorize setups applied such as layout, text size, basic
                  setup, and colors.
                </li>
                <li>
                  Memorize when the customer responds to a survey conducted by
                  the Company.
                </li>
              </ul>
            </ul>
            <li>
              <strong>Targeting cookies or advertising cookies</strong>
            </li>
            <p>
              These cookies are connected with the services provided by a 3rd
              party such as the buttons of 'good' and 'share'. The 3rd party
              provides these services by recognizing that the users visit the
              website of the Company.
            </p>
            <ul>
              <li>Examples of targeting cookies or advertising cookies:</li>
              <ul>
                <li>
                  They carry out PR to the users as targets in other websites by
                  connecting through social networks and these networks use the
                  information of users' visit.
                </li>
                <li>
                  They provide the information of users' visit to ad agencies so
                  that they can suggest an ad which may attract the interest of
                  the users.
                </li>
              </ul>
            </ul>
          </ul>

          <p>
            The users have an option for cookie installation. So, they may
            either allow all cookies by setting the option in the web browser,
            make each cookie checked whenever it is saved, or refuse all cookies
            to be saved. Provided that, if the user rejects the installation of
            cookies, it may be difficult for that user to use the parts of
            services provided by the Company.
          </p>

          <h5>5. User’s right</h5>
          <p>
            The users or their legal representatives, as main agents of the
            information, may exercise the following rights regarding the
            collection, use, and sharing of personal information by the Company:
          </p>

          <ul>
            <li>Access to personal information.</li>
            <li>Make corrections or deletion.</li>
            <li>
              Make temporary suspension of treatment of personal information.
            </li>
            <li>Request the withdrawal of their consent provided before.</li>
          </ul>

          <p>
            In order to exercise the above rights, a user can use the menu of
            customer service or contact the Company by sending a document or
            e-mails, or using the telephone to the company, the Company will
            take necessary measures without delay. The Company may reject the
            request of you only to the extent that there exists either proper
            cause as prescribed in the laws or equivalent cause.
          </p>

          <h5>6. Security</h5>
          <p>
            The Company regards the security of personal information of users as
            very important. The company constructs the following security
            measures to protect the users' personal information from any
            unauthorized access, release, use, or modification.
          </p>

          <ul>
            <li>
              <strong>Encryption of personal information</strong>
            </li>
            <ul>
              <li>
                Transmits users' personal information by using encrypted
                communication zone.
              </li>
              <li>
                Stores important information such as passwords only after
                encrypting it.
              </li>
            </ul>
            <li>
              <strong>Countermeasures against hacking</strong>
            </li>
            <ul>
              <li>
                Installs a system in the zone the external access to which is
                controlled so as to prevent leakage or damage of users' personal
                information by hacking or computer virus.
              </li>
            </ul>
            <li>
              <strong>
                Establish and execute an internal management plan.
              </strong>
            </li>
            <li>
              <strong>Operate access control system.</strong>
            </li>
          </ul>

          <h5>7. Protection of personal information of children</h5>
          <p>
            The service will not be provided to children under the age of 13.
            The company collects personal information of children only when to
            validate a user’s age. Information collected in the process of
            validation will be discarded immediately after the validation. The
            Company does not intentionally collect any personal information from
            children other than validation.
          </p>

          <p>
            <strong>
              Additional procedure for collecting personal information from
              children:
            </strong>{" "}
            However, if the Company collects any personal information from
            children under 13 or equivalent minimum age as prescribed in the
            laws in the relevant jurisdiction for the services for an
            unavoidable reason, the Company will go through the additional
            procedure of the followings for protecting that personal information
            of children:
          </p>

          <ul>
            <li>
              Verify, to the extent that efforts are reasonably made, whether
              they are children of the age at which consent from their guardian
              is required and the consenting person is an authorized one.
            </li>
            <li>
              Obtain consent from the parents or guardian of children so as to
              collect personal information of children or directly send the
              information of products and services of the Company.
            </li>
            <li>
              Give the parents or guardian of children a notice of Company's
              policy of privacy protection for children including the items,
              purpose, and sharing of personal information collected.
            </li>
            <li>
              Grant to legal representatives of children a right to 1) access to
              personal information of that children, 2) correction or deletion
              of personal information, 3) temporarily suspend the treatment of
              personal information, 4) and request for withdrawal of their
              consent provided before.
            </li>
            <li>
              Limit the amount of personal information exceeding those necessary
              for participation in online activities.
            </li>
          </ul>

          <h5>8. Modification of Privacy Protection Policy</h5>
          <p>
            The Company has the right to amend or modify this Policy from time
            to time and, in such a case, the Company will make a public notice
            of it through the bulletin board of its website and application
            service (or through individual notice such as written document, fax
            or e-mail) and obtain consent from the users if required by relevant
            laws.
          </p>

          <h5>9. Others</h5>
          <p>
            [Option to select 'data transfer to other countries' in Appendix of
            Personal Privacy Policy]
          </p>
          <p>
            [Option to select 'sites and service of 3rd party' in Appendix of
            Personal Privacy Policy]
          </p>
          <p>
            [Option to select 'guidelines for residents in California' in
            Appendix of Personal Privacy Policy]
          </p>
          <p>
            [Option to select 'guidelines for residents in Korea' in Appendix of
            Personal Privacy Policy]
          </p>

          <h5>10. Contact information of Company</h5>
          <p>
            Please use one of the following methods to contact the Company
            should you have any queries in respect to this policy or wish to
            update your information:
          </p>

          <ul>
            <li>
              <strong>Company name:</strong> Looko Corporation
            </li>
            <li>
              <strong>Address:</strong> 10F, 27, Dogok-ro 3-gil, Gangnam-gu,
              Seoul, South Korea
            </li>
            <li>
              <strong>Tel.:</strong> 82-42-860-0786
            </li>
            <li>
              <strong>E-mail:</strong> support@alcoset.app
            </li>
          </ul>

          <p>Effective December 1, 2020.</p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPollicy;
