import { Link } from "react-router-dom";
import "../App.scss";

const links = [
  {
    name: "about us",
    path: "/",
  },
  {
    name: "contact",
    path: "/",
  },
  {
    name: "privacy policy",
    path: "/privacy",
  },
  {
    name: "terms and conditions",
    path: "/terms",
  },
];

export const Footer = () => {
  return (
    <>
      <footer className="app__footer">
        <ul className="app__footer__list">
          {links.map((link) => (
            <li className="text-capitalize ">
              <Link to={link.path} className="text-dark text-decoration-none">
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        <p className="app__footer__text">
          Powered by Medialane Copyright 2023-2024
        </p>
      </footer>
    </>
  );
};
