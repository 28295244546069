const TESTIMONIALS = [
  {
    title: "Shivam Chaudhary",
    subTitle: "UI/UX Designer",
    desc: "“I love using this app to plan for trips and events. this app has allowed me to play with clothes I already own.”",
    img: "https://api.dicebear.com/7.x/thumbs/svg?seed=shivam",
  },
  {
    title: "Jajang Miharha",
    subTitle: "Student",
    desc: "“AI recognition while taking pictures is excellent - most of the time the category and colors are correct, and materials sometimes too“",
    img: "https://api.dicebear.com/7.x/thumbs/svg?seed=jajang",
  },
];

export default TESTIMONIALS;
