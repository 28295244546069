import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
const TermsAndConditions = () => {
  return (
    <>
      <Navbar />

      {/* terms and conditions  */}
      <h6 className="text-center my-5 fw-bold pt-5">Terms and Conditions</h6>
      <div className="row w-100">
        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
          <section>
            <h6 className="fw-bold">1. Our Service</h6>
            <p>
              These Terms of Service ("Terms") govern your access to and use of
              the closette website, apps, and widgets (“closette ” or the
              “Service”). Please read these Terms carefully, and contact us if
              you have any questions. By accessing or using closette , you agree
              to be bound by these Terms, and our Privacy Policy.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">2. Using closette </h6>
            <p>a. Who can use closette </p>
            <p>
              You may use closette only if you can legally form a binding
              contract with closette , and only in compliance with these Terms
              and all applicable laws. When you create your closette account,
              you must provide us with accurate and complete information. You
              can’t use closette if it would be prohibited by U.S. sanctions.
              Any use or access by anyone under the age of 13 is not allowed. If
              you’re based in the EEA, you may only use closette if you are over
              the age at which you can provide consent to data processing under
              the laws of your country or if verifiable parental consent for
              your use of closette has been provided to us. Using closette may
              include downloading software to your computer, phone, tablet, or
              other device. You agree that we may automatically update that
              software, and these Terms will apply to any updates.
            </p>
            <p>b. Our license to you</p>
            <p>
              Subject to these Terms and our policies, we grant you a limited,
              non-exclusive, non-transferable, and revocable license to use our
              Service.
            </p>
            <p>c. Commercial use of closette </p>
            <p>
              Using closette for commercial purposes is strictly prohibited
              without the agreement of the Company.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">3. Your Content</h6>
            <p>a. Posting content</p>
            <p>
              closette allows you to post content, including photos, comments,
              links, and other materials. Anything that you post or otherwise
              make available on closette is referred to as "User Content." You
              retain all rights in, and are solely responsible for, the User
              Content you post to closette .
            </p>
            <p>b. How closette and other users can use your content</p>
            <p>
              You grant closette and our users a non-exclusive, royalty-free,
              transferable, sublicensable, worldwide license to use, store,
              display, reproduce, save, modify, create derivative works,
              perform, and distribute your User Content on closette solely for
              the purposes of operating, developing, providing, and using
              closette . Nothing in these Terms restricts other legal rights
              closette may have to User Content, for example under other
              licenses. We reserve the right to remove or modify User Content,
              or change the way it’s used in closette , for any reason. This
              includes User Content that we believe violates these Terms, or any
              other policies.
            </p>
            <p>c. How long we keep your content</p>
            <p>
              Following termination or deactivation of your account, or if you
              remove any User Content from closette , we may keep your User
              Content for a reasonable period of time for backup, archival, or
              audit purposes. closette and its users may retain and continue to
              use, store, display, reproduce, modify, create derivative works,
              perform, and distribute any of your User Content that other users
              have stored or shared on closette .
            </p>
            <p>d. Feedback you provide</p>
            <p>
              We value hearing from our users and are always interested in
              learning about ways we can make closette more awesome. If you
              choose to submit comments, ideas, or feedback, you agree that we
              are free to use them without any restriction or compensation to
              you. By accepting your submission, closette doesn’t waive any
              rights to use similar or related feedback previously known to
              closette or developed by its employees or obtained from sources
              other than you.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">4. Security</h6>
            <p>
              We care about the security of our users. While we work to protect
              the security of your content and account, closette can’t guarantee
              that unauthorized third parties won’t be able to defeat our
              security measures. We ask that you keep your password secure.
              Please notify us immediately of any compromise or unauthorized use
              of your account.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">
              5. Third Party Links, Sites, and Services
            </h6>
            <p>
              closette may contain links to third party websites, advertisers,
              services, special offers, or other events or activities that are
              not owned or controlled by closette . We don’t endorse or assume
              any responsibility for any such third party sites, information,
              materials, products, or services. If you access any third party
              website, service, or content from closette , you do so at your own
              risk and you agree that closette has no liability arising from
              your use of or access to any third party website, service, or
              content.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">6. Service Operation and Termination</h6>
            <p>
              closette may terminate or suspend your right to access or use
              closette for any reason on appropriate notice. We may terminate or
              suspend your access immediately and without notice if we have a
              good reason, including any violation of user obligation described
              in Section 7 of these Terms, critical maintenance problems, and
              etc. Upon termination, you continue to be bound by Sections 3.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">7. User Obligation</h6>
            <p>Users must follow the guidelines below.</p>
            <ul>
              <li>
                Don’t do anything or post any content that violates laws or
                regulations.
              </li>
              <li>Do not impersonate any person or organization.</li>
              <li>
                Don’t infringe anyone's intellectual property, privacy, or other
                rights.
              </li>
              <li>
                Don’t access, use, or tamper with our systems or our technical
                providers’ systems.
              </li>
              <li>
                Don’t break or circumvent our security measures or test the
                vulnerability of our systems or networks.
              </li>
              <li>
                Don’t try to interfere with people on closette or our hosts or
                networks, like sending a virus, overloading, spamming, or
                mail-bombing.
              </li>
              <li>
                Don’t collect or store personally identifiable information from
                closette or people on closette without permission.
              </li>
              <li>
                Don’t share your password, let anyone access your account or do
                anything that might put your account at risk.
              </li>
              <li>
                Don’t attempt to buy or sell access to your account, boards, or
                usernames, or otherwise transfer account features for
                compensation.
              </li>
            </ul>
          </section>

          <section>
            <h6 className="fw-bold">8. Disclaimers</h6>
            <p>
              Our Service and all content on closette are provided on an "as is"
              basis without warranty of any kind, whether express or implied.
              closette specifically disclaims any and all warranties and
              conditions of merchantability, fitness for a particular purpose,
              and non-infringement, and any warranties arising out of the course
              of dealing or usage of trade. closette takes no responsibility and
              assumes no liability for any User Content that you or any other
              person or third party posts or sends using our Service. You
              understand and agree that you may be exposed to User Content
              that’s inaccurate, objectionable, inappropriate for children, or
              otherwise unsuited to your purpose. If you're a consumer in the
              EEA, we don’t exclude or limit any liability for gross negligence,
              intent, or death or personal injury caused by our negligence or
              willful misconduct.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">9. Limitation of Liability</h6>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, closette SHALL NOT BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
              PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
              INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
              GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR
              ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (B)
              ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE,
              INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR
              ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; OR (C)
              UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR
              CONTENT. IN NO EVENT SHALL closette 'S AGGREGATE LIABILITY FOR ALL
              CLAIMS RELATING TO THE SERVICE EXCEED TEN THOUSAND KOREAN WON (KRW
              10000).
            </p>
            <p>
              If we cause damage to you and you're a consumer in the EEA, the
              above doesn’t apply. Instead, closette 's liability will be
              limited to foreseeable damages arising due to a breach of material
              contractual obligations typical for this type of contract.
              closette isn’t liable for damages that result from a non-material
              breach of any other applicable duty of care. This limitation of
              liability won’t apply to any statutory liability that cannot be
              limited, to liability for death or personal injury caused by our
              negligence or willful misconduct, or if and to exclude our
              responsibility for something we have specifically promised to you.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">10. Arbitration</h6>
            <p>
              For any dispute you have with closette , you agree to first
              contact us and try to resolve the dispute with us informally. If
              we need to contact you, we will do so at the email address on your
              closette account. If closette hasn’t been able to resolve the
              dispute with you informally, we each agree to resolve any claim,
              dispute, or controversy (excluding claims for injunctive or other
              equitable relief) arising out of or in connection with or relating
              to these Terms through binding arbitration or (for qualifying
              claims) in small claims court.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">11. Governing Law and Jurisdiction</h6>
            <p>
              These Terms shall be governed by the laws of South Korea, without
              respect to its conflict of laws principles. If you are not a
              consumer in the EEA, the exclusive place of jurisdiction for all
              disputes arising from or in connection with this agreement is
              Daejeon County, Daejeon, and our dispute will be determined under
              the laws of South Korea. If you are a consumer in the EEA, this
              won’t deprive you of any protection you have under the law of the
              country where you live and access to the courts in that country.
            </p>
          </section>

          <section>
            <h6 className="fw-bold">12. General Terms</h6>
            <p>a. Notification procedures and changes to these Terms</p>
            <p>
              We reserve the right to determine the form and means of providing
              notifications to you, and you agree to receive legal notices
              electronically if that’s what we decide. We may revise these Terms
              from time to time, and the most current version will always be
              posted on our website. If a revision, in our discretion, is
              material, we’ll notify you. By continuing to access or use
              closette after revisions become effective, you agree to be bound
              by the new Terms. If you don’t agree to the new terms, please stop
              using closette .
            </p>
            <p>b. Assignment</p>
            <p>
              These Terms, and any rights and licenses granted hereunder, may
              not be transferred or assigned by you but may be assigned by
              closette without restriction. Any attempted transfer or assignment
              in violation hereof shall be null and void. If you’re a consumer
              in the EEA, either you or closette may assign this agreement, and
              any rights and licenses granted under it, to a third party. In the
              case of such an assignment by closette , you are entitled to
              terminate the agreement with immediate effect by deactivating your
              account. closette will provide you with reasonable notice of any
              such assignment.
            </p>
            <p>c. Entire agreement/severability</p>
            <p>
              These Terms, together with the Privacy Policy and any amendments
              and any additional agreements you may enter into with closette ,
              shall constitute the entire agreement between you and closette
              concerning the Service. If any provision of these Terms is deemed
              invalid, then that provision will be limited or eliminated to the
              minimum extent necessary, and the remaining provisions of these
              Terms will remain in full force and effect.
            </p>
            <p>d. No waiver</p>
            <p>
              No waiver of any term of these Terms shall be deemed a further or
              continuing waiver of such term or any other term, and closette 's
              failure to assert any right or provision under these Terms shall
              not constitute a waiver of such right or provision.
            </p>
            <p>e. Parties</p>
            <p>
              These Terms are a contract between you and Looko Corporation, a
              company of South Korea with its registered office at 322 Building
              11, 218 Gajeong-ro, Yuseong-gu, Daejeon, 34129, South Korea.
            </p>
          </section>

          <footer>
            <p>Effective December 1, 2020</p>
          </footer>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
